import {computed, ref} from 'vue';
import {fetchAgeGroupSpecialtiesRelations} from "../services";


export function useAgeGroupSpecialties({ props }) {
  const ageGroupSpecialtiesRelations = ref([
    ...props.professionalSpecialties.map((specialty) => {
      return {
        id: specialty.docline_api_id,
        name: specialty.name,
        ageGroup: null
      }
    })
  ]);

  const isLoadingAgeGroupSpecialtiesRelations = ref(false);
  const isNetworkError = ref(false);

  const retrieveAgeGroupSpecialtiesRelations = async (professionalId) => {
    try {
      isNetworkError.value = false;
      isLoadingAgeGroupSpecialtiesRelations.value = true;

      const response = await fetchAgeGroupSpecialtiesRelations(professionalId);

      if (response.specialties) {
        ageGroupSpecialtiesRelations.value = ageGroupSpecialtiesRelations.value.map((relation) => {
          const specialty = response.specialties.find((remoteRelation) => remoteRelation.specialtyId === relation.id);

          if (specialty) {
            return {
              ...relation,
              ageGroup: specialty.ageGroup
            }
          }

          return relation;
        })
      }
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingAgeGroupSpecialtiesRelations.value = false;
    }
  };

  return {
    ageGroupSpecialtiesRelations,
    retrieveAgeGroupSpecialtiesRelations
  };
}
