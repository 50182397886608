<template>
  <div class="age-group-selector__container">
    <p class="age-group-selector__label">{{ specialtyName }}</p>
    <v-autocomplete
      v-model="localValue"
      :items="options"
      outlined
      hide-selected
      clearable
      item-text="name"
      :placeholder="$t('age_group_selector.select_option')"
      item-value="id"
      :error-messages="validationError"
      :disabled="disabled"
    >
      <template v-slot:item="{ item }">
        <div class="py-1">
          <span class="age-group-selector__item">
            {{ item.name }}
          </span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useAgeGroupSelector } from '@/use/Minors/useAgeGroupSelector';
import CustomVmodelMixin from "@/mixins/custom-vmodel.mixin";

export default {
  name: 'AgeGroupSelector',
  mixins: [
    CustomVmodelMixin
  ],
  props: {
    specialty: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      options
    } = useAgeGroupSelector();

    return {
      options
    };
  },

  data: function() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth'))
    };
  },

  computed: {
    specialtyName() {
      return this.specialty.name;
    }
  }
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;

.age-group-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    font-size: rem(16);
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }
}


.age-group-selector__container {
  &
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
