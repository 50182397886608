import {
  getHeader,
  ageGroupSpecialtiesUrl,
} from '@/config/config';
import axios from 'axios';

export const fetchAgeGroupSpecialtiesRelations = async (
  professionalId
) => {
  const url = new URL(ageGroupSpecialtiesUrl(professionalId));

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;
};

export const setAgeGroupToProfessionalSpecialty = async (
  professionalId,
  relationData
) => {
  const url = new URL(ageGroupSpecialtiesUrl(professionalId));

  await axios.put(url.toString(), {
    specialties: relationData,
  },{
    headers: getHeader(),
  });
};
