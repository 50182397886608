<template>
  <div class="age-group-selectors">
    <template v-for="ageGroupSpecialtyRelation in ageGroupSpecialtiesRelations">
      <AgeGroupSelector v-model="ageGroupSpecialtyRelation.ageGroup" :specialty="{
                                id: ageGroupSpecialtyRelation.id,
                                name: ageGroupSpecialtyRelation.name,
                              }"/>
    </template>
  </div>
</template>
<script>
import AgeGroupSelector from "@/MinorsTreatment/AgeGroupSpecialties/components/AgeGroupSelector.vue";
import {useAgeGroupSpecialties} from "@/MinorsTreatment/AgeGroupSpecialties/use/useAgeGroupSpecialties";

export default {
  name: "AgeGroupSpecialties",
  components: {
    AgeGroupSelector
  },
  props: {
    professionalId: {
      type: String,
      default: null
    },
    professionalSpecialties: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data: function () {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  setup(props) {
    const {
      ageGroupSpecialtiesRelations,
      retrieveAgeGroupSpecialtiesRelations
    } = useAgeGroupSpecialties({ props });

    return {
      ageGroupSpecialtiesRelations,
      retrieveAgeGroupSpecialtiesRelations
    };
  },

  watch: {
    ageGroupSpecialtiesRelations: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    }
  },

  created() {
    this.retrieveAgeGroupSpecialtiesRelations(this.professionalId);
  }
}
</script>

<style lang="scss">
.age-group-selectors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
</style>
