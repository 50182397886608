import { ref } from 'vue';

export function useAgeGroupSelector() {
  const options = ref([
    {
      id: 'minors_and_adults',
      name: 'Adultos y menores',
    },
    {
      id: 'adults',
      name: 'Solo adultos',
    },
    {
      id: 'minors',
      name: 'Solo menores',
    },
  ]);

  return {
    options,
  };
}
